import { Link } from "react-router-dom";

export default function Home() {
    return (
        <>
            <Header />
            <UnderstandingSection />
            <ServicesSection />
        </>
    );
}

const Header = () => {
    return (
        <header className="bg-blue-900 text-white">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12 flex flex-col">
                <h3 className="text-4xl md:text-4xl font-bold mb-4 text-center">
                    Ensure Safety and Comfort for Your Loved Ones at Home
                </h3>
                <p className="text-lg md:text-xl mb-8 text-center">
                    We offer specialized home renovation services so you or your loved ones can happily age in the home you love.
                </p>
                <Link to="/contact">
                    <button className="bg-yellow-500 text-blue-900 py-3 px-8 rounded-lg font-semibold shadow-lg hover:bg-yellow-600 transition duration-300 w-max-200" href="/contact">
                        Get a Free Quote Today
                    </button>
                </Link>
            </div>
        </header >
    );
};

const UnderstandingSection = () => {
    return (
        <section className="py-16 bg-gray-100">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <h2 className="text-3xl font-bold mb-8">Why senior proofing your house is essential?</h2>
                <p className="mb-8">
                    As our loved ones age, their homes can become hazardous without proper modifications. Senior-proofing helps minimize risks like falls, enhances accessibility, and ensures a comfortable living environment.
                </p>

                <div class="">
                    <ol class="list-decimal list-inside space-y-4">
                        <li class="font-semibold text-left">
                            Enhanced Safety
                            <ul class="list-disc list-inside pl-4 space-y-2 font-normal">
                                <li class="text-left">Fall Prevention: Many home modifications, such as installing grab bars, non-slip flooring, and walk-in showers, are designed to prevent falls, which are a leading cause of injury among seniors.</li>
                                <li class="text-left">Improved Mobility: Features like ramps, stairlifts, and widened doorways ensure that individuals with mobility issues can move safely throughout their homes.</li>
                            </ul>
                        </li>
                        <li class="font-semibold text-left">
                            Increased Independence
                            <ul class="list-disc list-inside pl-4 space-y-2 font-normal">
                                <li class="text-left">Self-Sufficiency: Modifications such as accessible kitchen counters, easy-to-reach storage, and user-friendly appliances enable seniors to perform daily activities independently, reducing reliance on caregivers or family members.</li>
                                <li class="text-left">Autonomy: By adapting their homes to better suit their needs, seniors can maintain control over their living environment and daily routines.</li>
                            </ul>
                        </li>
                        <li class="font-semibold text-left">
                            Comfort and Quality of Life
                            <ul class="list-disc list-inside pl-4 space-y-2 font-normal">
                                <li class="text-left">Enhanced Living Conditions: Renovations can improve overall comfort by addressing issues like inadequate lighting, uncomfortable bathroom fixtures, or inaccessible kitchen layouts.</li>
                                <li class="text-left">Personalization: Customizing a home to reflect personal preferences and needs can make living spaces more enjoyable and suited to an individual’s lifestyle.</li>
                            </ul>
                        </li>
                        <li class="font-semibold text-left">
                            Cost-Effective Care
                            <ul class="list-disc list-inside pl-4 space-y-2 font-normal">
                                <li class="text-left">Reducing Long-Term Costs: Investing in home modifications can delay or avoid the need for more expensive care options, such as assisted living facilities or nursing homes.</li>
                                <li class="text-left">Preventative Measures: By addressing potential hazards and accessibility issues early, renovations can help prevent costly injuries and health complications.</li>
                            </ul>
                        </li>
                        <li class="font-semibold text-left">
                            Psychological Well-Being
                            <ul class="list-disc list-inside pl-4 space-y-2 font-normal">
                                <li class="text-left">Emotional Comfort: Staying in a familiar environment can provide emotional stability and reduce stress, which is beneficial for mental health.</li>
                                <li class="text-left">Sense of Security: Knowing that their home is equipped to handle their needs helps seniors feel safer and more secure.</li>
                            </ul>
                        </li>
                        <li class="font-semibold text-left">
                            Family Peace of Mind
                            <ul class="list-disc list-inside pl-4 space-y-2 font-normal">
                                <li class="text-left">Reduced Worry: Family members can feel more at ease knowing that their loved one’s home is safe and adapted to their needs, reducing concerns about accidents or accessibility issues.</li>
                                <li class="text-left">Quality Family Time: Safe and accessible homes make it easier for family members to visit and spend time together, fostering stronger relationships.</li>
                            </ul>
                        </li>
                        <li class="font-semibold text-left">
                            Compliance with Accessibility Standards
                            <ul class="list-disc list-inside pl-4 space-y-2 font-normal">
                                <li class="text-left">Meeting Regulations: Renovations can ensure that homes meet or exceed current accessibility standards and codes, which is especially important for individuals with specific needs.</li>
                                <li class="text-left">Future-Proofing: Making a home accessible today can prepare it for future needs, whether due to aging or evolving health conditions.</li>
                            </ul>
                        </li>
                        <li class="font-semibold text-left">
                            Government Support
                            <ul class="list-disc list-inside pl-4 space-y-2 font-normal">
                                <li class="text-left">Financial Assistance: Various tax credits and rebates are available to help offset the costs of home renovations for seniors, making it more affordable to undertake necessary modifications.</li>
                            </ul>
                        </li>
                    </ol>
                </div>

                <div className="space-y-4">
                    <p className="mb-8">
                        In summary, renovating a home to meet the needs of seniors is vital for enhancing safety, promoting independence, and improving overall quality of life. It also provides financial and emotional benefits, making it a worthwhile investment for both seniors and their families.
                    </p>
                </div>
            </div>
        </section>
    );
};

const ServicesSection = () => {
    return (
        <section className="py-16">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <h2 className="text-3xl font-bold mb-8">Our Comprehensive Services</h2>
                <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 space-y-8">
                    <p>Transform your home into a safe, comfortable, and accessible space with our specialized renovation services tailored for seniors. We understand the unique needs of aging in place, and we're here to help you maintain your independence and enhance your quality of life.</p>

                    <h2 class="text-lg font-semibold">Bathroom Safety Upgrades:</h2>
                    <ul class="list-disc list-inside pl-4 space-y-2">
                        <li>Experience peace of mind with walk-in showers or tubs designed for easy access.</li>
                        <li>Ensure safety with strategically placed grab bars and raised toilet seats.</li>
                        <li>Prevent slips and falls with our durable, non-slip flooring options.</li>
                    </ul>

                    <h2 class="text-lg font-semibold">Accessible Kitchen Remodeling:</h2>
                    <ul class="list-disc list-inside pl-4 space-y-2">
                        <li>Enjoy cooking again with lowered countertops and cabinets that put everything within easy reach.</li>
                        <li>Maximize storage and convenience with pull-out shelves and lever-style handles.</li>
                        <li>Upgrade to non-slip, easy-to-clean flooring for a safer kitchen environment.</li>
                    </ul>

                    <h2 class="text-lg font-semibold">Ramps and Stairlift Installations:</h2>
                    <ul class="list-disc list-inside pl-4 space-y-2">
                        <li>Say goodbye to mobility challenges with custom-built ramps and stairlifts.</li>
                        <li>Navigate multi-story homes effortlessly with our top-of-the-line solutions.</li>
                    </ul>

                    <h2 class="text-lg font-semibold">Doorway and Hallway Expansions:</h2>
                    <ul class="list-disc list-inside pl-4 space-y-2">
                        <li>Move freely through your home with widened doorways and hallways that accommodate wheelchairs and walkers.</li>
                        <li>Eliminate obstacles and create an open, accessible living space.</li>
                    </ul>

                    <h2 class="text-lg font-semibold">Enhanced Lighting Solutions:</h2>
                    <ul class="list-disc list-inside pl-4 space-y-2">
                        <li>Brighten your home with energy-efficient lighting that ensures safety and visibility.</li>
                        <li>Add motion-activated lights and illuminated switches for convenience and peace of mind.</li>
                    </ul>

                    <h2 class="text-lg font-semibold">Smart Home Technology Integration:</h2>
                    <ul class="list-disc list-inside pl-4 space-y-2">
                        <li>Take control of your home with voice-activated assistants, smart thermostats, and security systems.</li>
                        <li>Enjoy the convenience of remote-controlled lighting, blinds, and appliances.</li>
                    </ul>

                    <h2 class="text-lg font-semibold">Flooring Upgrades:</h2>
                    <ul class="list-disc list-inside pl-4 space-y-2">
                        <li>Reduce the risk of trips and falls with our low-pile carpet options.</li>
                        <li>Choose from smooth, slip-resistant flooring materials like vinyl or laminate.</li>
                        <li>Remove thresholds for a seamless, hazard-free living space.</li>
                    </ul>

                    <h2 class="text-lg font-semibold">Handrails and Grab Bars:</h2>
                    <ul class="list-disc list-inside pl-4 space-y-2">
                        <li>Stay steady and secure with handrails along stairs, hallways, and outdoor steps.</li>
                        <li>Get the support you need with grab bars placed exactly where you need them most.</li>
                    </ul>

                    <h2 class="text-lg font-semibold">Accessible Entryways:</h2>
                    <ul class="list-disc list-inside pl-4 space-y-2">
                        <li>Make entering your home a breeze with no-step entries and threshold ramps.</li>
                        <li>Enjoy the ease of automatic door openers for effortless access.</li>
                    </ul>

                    <h2 class="text-lg font-semibold">Bedroom Modifications:</h2>
                    <ul class="list-disc list-inside pl-4 space-y-2">
                        <li>Make getting in and out of bed easier with adjusted bed heights.</li>
                        <li>Organize your wardrobe with closet systems designed for easy access.</li>
                        <li>Ensure your bedroom is fully functional with well-placed light switches and outlets.</li>
                    </ul>

                    <p>Let us help you create a home that meets your needs today and for years to come. Our expert team is dedicated to providing high-quality renovations that keep you comfortable, safe, and independent in your own home. Contact us today to start your home transformation journey.</p>
                </div>

            </div>
        </section>
    );
};