import React, { useState } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';  // Import NavLink
import Home from './pages/Home';
import Terms from './pages/Terms';
import About from './pages/About';
import Insight from './pages/Insight';
import Contact from './pages/Contact';

function App() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="">
            <nav className="bg-blue-900 p-4">
                <div className="flex items-center justify-between">
                    <div className="text-white text-2xl font-bold">
                        <NavLink to="/" exact className="hover:underline">CareProofing.com</NavLink>
                    </div>
                    <div className="md:hidden">
                        <button onClick={toggleMenu} className="text-white">
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
                                ></path>
                            </svg>
                        </button>
                    </div>
                    <ul
                        className={`${
                            isOpen ? 'block' : 'hidden'
                        } md:flex md:items-center md:space-x-6 space-y-4 md:space-y-0 md:mt-0 mt-4 text-white`}
                    >
                        <li>
                            <NavLink 
                                to="/" 
                                exact 
                                className={({ isActive }) => 
                                    isActive ? 'font-bold' : 'hover:underline'
                                }
                            >
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                                to="/contact" 
                                className={({ isActive }) => 
                                    isActive ? 'font-bold' : 'hover:underline'
                                }
                            >
                                Contact
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                                to="/insight" 
                                className={({ isActive }) => 
                                    isActive ? 'font-bold' : 'hover:underline'
                                }
                            >
                                Insight
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                                to="/about" 
                                className={({ isActive }) => 
                                    isActive ? 'font-bold' : 'hover:underline'
                                }
                            >
                                About us
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                                to="/terms" 
                                className={({ isActive }) => 
                                    isActive ? 'font-bold' : 'hover:underline'
                                }
                            >
                                Terms
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/insight" element={<Insight />} />
                <Route path="/about" element={<About />} />
                <Route path="/terms" element={<Terms />} />
            </Routes>
            <Footer />
        </div>
    );
}

const Footer = () => {
    return (
        <footer className="py-8">
            <div className="container mx-auto px-4 text-center">
                <p className="text-sm">© 2024 CareProofing.com. All Rights Reserved.</p>
            </div>
        </footer>
    );
};

export default App;
