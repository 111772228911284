import React from 'react';

export default function Insight() {
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-center mb-8">Tax Benefits</h1>

      <p className="text-lg leading-relaxed mb-8">
        In Canada, there are several tax benefits and incentives available to help seniors and individuals with disabilities offset the costs of home renovations aimed at improving accessibility and safety. These programs encourage aging in place by making home modifications more affordable. Here’s an overview of the key tax benefits:
      </p>

      <ol className="list-decimal list-inside space-y-6">
        <li className="font-semibold text-left">
          Home Accessibility Tax Credit (HATC)
          <p className="text-left mt-2">
            <span className="font-bold">What It Is:</span> The Home Accessibility Tax Credit (HATC) is a federal non-refundable tax credit designed to help seniors (aged 65 and older) and individuals eligible for the Disability Tax Credit (DTC) cover the cost of home renovations that improve accessibility, mobility, and safety.
          </p>
          <p className="text-left mt-2">
            <span className="font-bold">Eligibility:</span> To be eligible, you must be a senior or someone eligible for the DTC, or be making the renovations for an eligible individual. The renovation must be intended to allow the individual to live more independently or reduce the risk of harm within the home.
          </p>
          <p className="text-left mt-2">
            <span className="font-bold">What It Covers:</span> Eligible expenses include the installation of ramps, walk-in bathtubs, wheel-in showers, grab bars, and other modifications that enhance mobility and reduce the risk of falls.
          </p>
          <p className="text-left mt-2">
            <span className="font-bold">Tax Credit:</span> You can claim up to $10,000 in eligible expenses per year, resulting in a maximum tax credit of $1,500 (15% of $10,000).
          </p>
        </li>
        
        <li className="font-semibold text-left">
          Seniors' Home Safety Tax Credit (Ontario)
          <p className="text-left mt-2">
            <span className="font-bold">What It Is:</span> The Seniors’ Home Safety Tax Credit is a temporary, refundable tax credit offered by the Ontario government. It is designed to help seniors aged 65 and older, or those living with them, make their homes safer and more accessible.
          </p>
          <p className="text-left mt-2">
            <span className="font-bold">Eligibility:</span> Available to all Ontario seniors or those who live with a senior relative. There are no income restrictions, making it accessible to a wide range of residents.
          </p>
          <p className="text-left mt-2">
            <span className="font-bold">What It Covers:</span> This credit covers various home modifications, such as the installation of grab bars, ramps, stairlifts, non-slip flooring, and widening doorways to improve accessibility.
          </p>
          <p className="text-left mt-2">
            <span className="font-bold">Tax Credit:</span> You can claim 25% of up to $10,000 in eligible expenses, providing a maximum credit of $2,500. Since this is a refundable tax credit, it can benefit you even if you don’t owe any tax.
          </p>
        </li>

        <li className="font-semibold text-left">
          Disability Tax Credit (DTC)
          <p className="text-left mt-2">
            <span className="font-bold">What It Is:</span> The Disability Tax Credit (DTC) is a non-refundable tax credit that helps people with disabilities or their supporting family members reduce the amount of income tax they may owe. While not directly related to home renovations, being eligible for the DTC can provide additional financial relief.
          </p>
          <p className="text-left mt-2">
            <span className="font-bold">Eligibility:</span> You must have a severe and prolonged impairment in physical or mental functions, certified by a qualified practitioner.
          </p>
          <p className="text-left mt-2">
            <span className="font-bold">What It Covers:</span> While the DTC itself doesn’t cover renovation costs, being eligible for the DTC can enhance your eligibility for other tax credits and benefits, like the HATC.
          </p>
        </li>
      </ol>

      <h2 className="text-2xl font-bold text-left mt-8 mb-4">How to Claim These Tax Credits</h2>

      <ul className="list-disc list-inside space-y-4">
        <li className="text-left">
          <span className="font-semibold">Receipts:</span> Keep all receipts and documentation related to your renovations. We will also store a digital copy on your behalf should you need it. You’ll need to provide these when filing your tax return to claim the credits.
        </li>
        <li className="text-left">
          <span className="font-semibold">Tax Forms:</span> Use the appropriate sections of your tax return forms (e.g., Schedule 1 for HATC) to claim the credits. For provincial credits like Ontario’s, follow the specific guidelines provided by your province’s tax authority.
        </li>
        <li className="text-left">
          <span className="font-semibold">Consult a Tax Professional:</span> Consider consulting a tax professional to ensure you’re maximizing all available credits and complying with the requirements. We can connect you to some of our tax professional partners dealing in these matters.
        </li>
      </ul>
    </div>
  );
}
