import React, { useState } from 'react';

export default function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        areasOfConcern: '',
        preferredContactTime: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic
        alert('Quote requested successfully!');
    };

    return (
        <section className="py-16 bg-gray-100">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-8">Get a Free Quote in Minutes</h2>
                <form onSubmit={handleSubmit} className="max-w-lg mx-auto space-y-4">
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Your Name"
                        className="w-full p-3 rounded-lg border border-gray-300"
                    />
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Your Email"
                        className="w-full p-3 rounded-lg border border-gray-300"
                    />
                    <input
                        type="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Your Phone"
                        className="w-full p-3 rounded-lg border border-gray-300"
                    />
                    <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        placeholder="Your Address"
                        className="w-full p-3 rounded-lg border border-gray-300"
                    />
                    <input
                        type="text"
                        name="areasOfConcern"
                        value={formData.areasOfConcern}
                        onChange={handleChange}
                        placeholder="Areas of Concern (e.g., bathroom, stairs)"
                        className="w-full p-3 rounded-lg border border-gray-300"
                    />
                    <input
                        type="text"
                        name="preferredContactTime"
                        value={formData.preferredContactTime}
                        onChange={handleChange}
                        placeholder="Preferred Contact Time"
                        className="w-full p-3 rounded-lg border border-gray-300"
                    />
                    <button
                        type="submit"
                        className="bg-yellow-500 text-blue-900 py-3 px-8 rounded-lg font-semibold shadow-lg hover:bg-yellow-600 transition duration-300 w-full"
                    >
                        Request My Free Quote
                    </button>
                </form>
            </div>
        </section>
    );
};
