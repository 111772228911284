import React from 'react';

export default function About() {
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-center mb-8">What Makes Us Different</h1>

      <p className="text-lg leading-relaxed mb-8">
        At Careproof, we understand that choosing the right partner for your home renovation needs is about more than just expertise—it's about trust, compassion, and a commitment to enhancing your quality of life. Here’s what sets us apart:
      </p>

      <ol className="list-decimal list-inside space-y-6">
        <li className="font-semibold text-left">
          Senior-Centric Design Expertise
          <p className="text-left mt-2">
            We specialize in creating safe, accessible, and comfortable living environments tailored specifically to the needs of seniors. Our team is trained to understand the unique challenges of aging in place, ensuring that every detail of your renovation contributes to your independence and well-being.
          </p>
        </li>
        <li className="font-semibold text-left">
          Comprehensive, End-to-End Service
          <p className="text-left mt-2">
            From initial consultation to the final touches, we handle every aspect of your renovation. We’ll guide you through design choices, manage the installation process, and ensure your project is completed on time and within budget, with minimal disruption to your daily life.
          </p>
        </li>
        <li className="font-semibold text-left">
          Compassionate and Respectful Approach
          <p className="text-left mt-2">
            We treat your home as if it were our own, with respect and care at the forefront of everything we do. Our friendly, professional team is here to listen to your needs and concerns, providing personalized solutions that reflect your style and preferences.
          </p>
        </li>
        <li className="font-semibold text-left">
          Transparent Process with Innovative Software
          <p className="text-left mt-2">
            We believe in complete transparency throughout your renovation journey. That’s why we use cutting-edge project management software that allows you to track progress in real-time, view updates, and communicate with our team easily. You’ll always know what’s happening, when, and how much it costs, ensuring there are no surprises along the way.
          </p>
        </li>
        <li className="font-semibold text-left">
          Expertise in Tax Rebates and Credits
          <p className="text-left mt-2">
            We go beyond just renovation—we help you maximize the financial benefits available to you. Our knowledge of Canada’s tax rebate programs means we can guide you on which services may qualify for credits like the Home Accessibility Tax Credit (HATC), helping you save money while enhancing your home.
          </p>
        </li>
        <li className="font-semibold text-left">
          Commitment to Safety and Compliance
          <p className="text-left mt-2">
            Your safety is our top priority. We adhere to the highest standards of safety and accessibility, ensuring that all modifications meet or exceed local building codes and regulations. Our solutions are designed to provide peace of mind for you and your loved ones.
          </p>
        </li>
        <li className="font-semibold text-left">
          Customized Solutions
          <p className="text-left mt-2">
            No two homes—or clients—are the same. We take the time to understand your unique needs and preferences, offering customized renovation solutions that are tailored to your lifestyle. Whether it’s a small modification or a full-scale renovation, we deliver results that are as individual as you are.
          </p>
        </li>
        <li className="font-semibold text-left">
          Flexible Financing Options
          <p className="text-left mt-2">
            We understand that home renovations can be a significant investment. That’s why we offer flexible financing options to make your dream home more accessible. Whether you need extended payment plans or fair-interest financing, we work with you to find a solution that fits your budget, making it easier to achieve the home modifications you need.
          </p>
        </li>
      </ol>

      <p className="text-lg leading-relaxed mt-8">
        With Careproof, you’re not just getting a renovation—you’re getting a partner who is dedicated to helping you live comfortably and safely in your own home for years to come. Let us show you the difference that our experience, compassion, and commitment can make.
      </p>
    </div>
  );
}
